<template>
  <div v-if="tableData">

    <el-table
        ref="hospitalizationTable"
        :data="tableData"
        :default-sort = "{prop: 'date', order: 'ascending'}"
        style="width: 100%"
        highlight-current-row
        @row-click="onRowClick"
        @row-contextmenu="onRowRightClick"
        @expand-change="onRowExpand"
        stripe>
      <el-table-column v-if="rowExpandable" type="expand">
      </el-table-column>
      <el-table-column
          prop="date"
          label="Einweisung"
          width="150"
          sortable>
        <template #default="scope">
          {{ $filters.germanDate(scope.row.date_start) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="date"
        label="Entlassung"
        width="150"
        sortable>
        <template #default="scope">
          {{ $filters.germanDate(scope.row.date_end) }}
        </template>
      </el-table-column>
      <el-table-column
          prop="cause"
          label="Grund">
      </el-table-column>
      <el-table-column
          v-if="rowDeleteable"
          width="70">
        <template #default="scope">
          <div class="text-right">
            <el-popconfirm
                :title="'Sie möchten die Zuweisung zum Krankenhausaufenthalt vom ' + $filters.germanDate(scope.row.date_start) + ' löschen?'"
                confirmButtonText='Ja, löschen'
                confirmButtonType="danger"
                cancelButtonText='Nein'
                iconColor="red"
                @confirm="onRowDeleteConfirmed(scope.row)">
              <template #reference>
                <el-button size="mini" type="warning" icon="el-icon-delete"></el-button>
              </template>
            </el-popconfirm>

          </div>
        </template>
      </el-table-column>
    </el-table>

  </div>
</template>

<script>
export default {
  name: "HospitalizationTable",
  props: {
      data: Array,
      expandable: Boolean,
      clickable: Boolean,
      deleteable: Boolean
  },
  emits: [
    'onRowDelete'
  ],
  components: {

  },
  data() {
    return {
      rowExpandable: this.expandable || false,
      rowClickable: this.clickable || false,
      rowDeleteable: this.deleteable || false,
      tableData: this.data
    }
  },
  mounted: function () {
  },
  /*
  computed: {
    var: function () {
    }
  }
  */
  watch: {
    data: function (val) {
      this.tableData = val
    }
  },
  methods: {
    onRowDeleteConfirmed(row) {
      this.$emit('onRowDelete', row)
    },
/*    onRowEditClick(row) {
    },
    onRowClick(row) {
      if(this.rowClickable) {
      }
    },*/
    onRowRightClick(row) {
      if(this.rowExpandable) {
        this.$refs.hospitalizationTable.setCurrentRow(row)
        this.$refs.hospitalizationTable.toggleRowExpansion(row)
      }
    },
    onRowExpand(row) {
      this.$refs.hospitalizationTable.setCurrentRow(row)
    }
}
}
</script>
<style scoped>
</style>
